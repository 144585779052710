import React from 'react';
import { PageProps } from 'gatsby';
import SEO from '~/components/seo';
import { ResetPasswordPage } from '~/components/ResetPassword';

const ResetPassword: React.FC<PageProps> = props => (
  <>
    <SEO
      pageContext={props.pageContext}
      title="Account.resetPassword.pageTitle"
    />
    <ResetPasswordPage {...props} />
  </>
);

export default ResetPassword;
