import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { ResetPasswordForm } from './Form';
import { PasswordRequirements } from '~/components/forms/PasswordRequirements';
import { PageShell } from '~/components/BaseElements/pageShell';
import { PageProps } from 'gatsby';
import * as Styled from './styles';
import { ROUTES } from '~/config';

export const ResetPasswordPage: React.FC<PageProps> = () => {
  const { t } = useTranslation();
  return (
    <PageShell>
      <Styled.CenteredContainer>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={8}>
            <Styled.PageTitle>
              <Trans i18nKey="Account.resetPassword.pageTitle" />
            </Styled.PageTitle>
          </Grid>
          <Grid item xs={12} md={8}>
            <Styled.PageBody>
              {t('Account.resetPassword.description')} <br />
              <Styled.Link to={ROUTES.forgotPassword}>
                {t('Account.resetPassword.requestAnother')}
              </Styled.Link>
            </Styled.PageBody>
            <PasswordRequirements />
          </Grid>
          <Grid item xs={12} md={8}>
            <ResetPasswordForm />
          </Grid>
        </Grid>
      </Styled.CenteredContainer>
    </PageShell>
  );
};
