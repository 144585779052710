import { useForm, RegisterOptions, UseFormMethods } from 'react-hook-form';
import { emailPattern } from '~/common/regex';

export interface ResetPasswordFormInterface {
  email: string;
  verificationCode: string;
  newPassword: string;
  confirmPassword: string;
}

interface UseResetPasswordForm {
  form: UseFormMethods<ResetPasswordFormInterface>;
  validationSchema: Map<keyof ResetPasswordFormInterface, RegisterOptions>;
}

export const useResetPasswordForm = (): UseResetPasswordForm => {
  const form = useForm<ResetPasswordFormInterface>({
    defaultValues: {
      email: '',
      verificationCode: '',
      newPassword: '',
      confirmPassword: '',
    },
  });

  const validationSchema = new Map<
    keyof ResetPasswordFormInterface,
    RegisterOptions
  >();
  validationSchema.set('email', { required: true, pattern: emailPattern });
  validationSchema.set('verificationCode', {
    required: true,
    validate: {
      digitsOnly: value => /^[0-9]+$/.test(value),
    },
  });
  validationSchema.set('newPassword', {
    required: true,
    minLength: 8,
    validate: {
      number: value => /[\d]{1}/.test(value),
      letter: value => /[a-zA-Z]/.test(value),
    },
  });
  validationSchema.set('confirmPassword', {
    required: true,
    validate: {
      match: value => value === form.getValues('newPassword'),
    },
  });

  return { form, validationSchema };
};
