import styled from 'styled-components';
import { BaseInput } from '~/components/forms/fields';
import { InternalLink } from '~/components/BaseElements';
import {
  Card,
  Container,
  Typography,
  TextField,
  Button,
} from '@material-ui/core';

export const PageTitle = styled(Typography).attrs({ variant: 'h4' })`
  ${props => props.theme.breakpoints.down('sm')} {
    font-size: 32px;
  }
`;

export const PageBody = styled(Typography)``;

export const Link = styled(InternalLink)`
  font-size: 12px;
`;

export const FormCard = styled(Card).attrs({ elevation: 1 })`
  padding: 0 1em 2em 1em;
`;

export const CenteredContainer = styled(Container).attrs({
  maxWidth: 'md',
  fixed: true,
})`
  padding: 2rem 0;
`;

export const SubmitButton = styled(Button).attrs({
  size: 'large',
  color: 'primary',
  variant: 'contained',
})`
  font-weight: bold;
  text-transform: none;
`;

export const PasswordInput: typeof TextField = styled(BaseInput).attrs({
  type: 'password',
})``;

export const EmailInput: typeof TextField = styled(BaseInput).attrs({
  type: 'email',
})``;

export const VerificationCodeInput: typeof TextField = styled(
  PasswordInput
).attrs({
  type: 'text',
})``;
