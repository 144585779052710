import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '~/state/hooks/useSnackbar';
import { useNavigate } from '~/state/hooks/useNavigate';
import { forgotPasswordSubmit, signIn } from '~/services/auth';
import { CardContent, CardActions, CircularProgress } from '@material-ui/core';
import {
  useResetPasswordForm,
  ResetPasswordFormInterface,
} from './useResetPasswordForm';
import * as Styled from './styles';

export const ResetPasswordForm: React.FC = () => {
  const { t } = useTranslation();
  const { navigate } = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { form, validationSchema } = useResetPasswordForm();

  const submitForm = async ({
    email,
    verificationCode,
    newPassword,
  }: ResetPasswordFormInterface) => {
    setLoading(true);
    try {
      await forgotPasswordSubmit(email, verificationCode, newPassword);
      await signIn(email, newPassword);
      showSnackbar(t('Account.resetPassword.success'), 'success');
      navigate('', { replace: true });
    } catch (error) {
      showSnackbar(t('Account.resetPassword.error'), 'error');
    } finally {
      setLoading(false);
    }
  };

  const getErrorMessage = (
    fieldParentKey: string,
    fieldName: keyof ResetPasswordFormInterface,
    errType?: string
  ): string =>
    errType ? t(`${fieldParentKey}.${fieldName}.errors.${errType}`) : '';

  return (
    <Styled.FormCard>
      <CardContent>
        <Styled.EmailInput
          name="email"
          data-test="email_input"
          error={!!form.errors.email}
          label={t('Account.resetPassword.email.label')}
          inputRef={form.register(validationSchema.get('email'))}
          helperText={getErrorMessage(
            'Account.resetPassword',
            'email',
            form.errors?.email?.type
          )}
        />
        <Styled.VerificationCodeInput
          name="verificationCode"
          data-test="verificationCode_input"
          error={!!form.errors.verificationCode}
          label={t('Account.resetPassword.verificationCode.label')}
          inputRef={form.register(validationSchema.get('verificationCode'))}
          helperText={getErrorMessage(
            'Account.resetPassword',
            'verificationCode',
            form.errors?.verificationCode?.type
          )}
        />
        <Styled.PasswordInput
          name="newPassword"
          data-test="newPassword_input"
          error={!!form.errors.newPassword}
          label={t('AccountPasswordFields.newPassword.label')}
          inputRef={form.register(validationSchema.get('newPassword'))}
          helperText={getErrorMessage(
            'AccountPasswordFields',
            'newPassword',
            form.errors?.newPassword?.type
          )}
        />
        <Styled.PasswordInput
          name="confirmPassword"
          data-test="confirmPassword_input"
          error={!!form.errors.confirmPassword}
          label={t('AccountPasswordFields.confirmPassword.label')}
          inputRef={form.register(validationSchema.get('confirmPassword'))}
          helperText={getErrorMessage(
            'AccountPasswordFields',
            'confirmPassword',
            form.errors?.confirmPassword?.type
          )}
        />
      </CardContent>
      <CardActions>
        <Styled.SubmitButton
          type="submit"
          onClick={form.handleSubmit(submitForm)}
          endIcon={loading && <CircularProgress color="inherit" size={18} />}
        >
          {t('Account.resetPassword.submitButton')}
        </Styled.SubmitButton>
      </CardActions>
    </Styled.FormCard>
  );
};
